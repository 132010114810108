import React, { useEffect } from "react"
import LayoutProvider from "../../../src/components/Layout"
import CotizacionesComponent from '../../components/DashboardComponents/Cotizaciones'
import { navigate } from 'gatsby';

const CertificadosPage = ({ location }) => {


  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <LayoutProvider title="Certificados" location={location}>
        <CotizacionesComponent/>
    </LayoutProvider>
  )
}

export default CertificadosPage
