import React, { useEffect } from "react"
import { ConsultaCotizacionesF } from "../../state/actions/Dashboard/CotizacionesActions"
import { useSelector, useDispatch } from "react-redux"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from "moment"

const CotizacionesComponent = () => {
  const dispatch = useDispatch()

  const Cotizaciones = useSelector(state => state.Cotizaciones.Cotizaciones)
  useEffect(() => {
    if (Cotizaciones.length === 0) {
      const ConsultaAtu = () => dispatch(ConsultaCotizacionesF())
      ConsultaAtu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="contenedor">
      <div style={{flex: 1}}>

      </div>
      <TableContainer component={Paper}>
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Empleador</TableCell>
                  <TableCell>Fecha Pago</TableCell>
                  <TableCell>Monto Pagado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Cotizaciones.map((dato, i) => (
                            <TableRow key={i} >
                              <TableCell scope="row">{dato.nombre_pagador}</TableCell>
                              <TableCell>{moment(dato.periodo+"01").format('DD-MM-YYYY')}</TableCell>
                              <TableCell>${Intl.NumberFormat().format(dato.monto)}</TableCell>
                            </TableRow>
                          ))}
              </TableBody>
            </Table>
          </TableContainer>
    </div>
  )
}

export default CotizacionesComponent